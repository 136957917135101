import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useMeetRoom } from '../../contexts/useMeetRoom';
import api from '../../services/api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputError } from '../InputError';

import { 
    Container, 
    CustomInput, 
    Form, 
    Button, 
    ButtonsContainer, 
    HeaderFormContainer, 
    HeaderTitle,
    InputsContainer
} from './styles';


/* ===== TIPAGENS ===== */
type FormData = {
    nome: string;
    descricao: string;
}


/* ===== OTHERS ===== */
Modal.setAppElement('#root');

const validationSchema = yup.object({
    nome: yup.string().max(20).required("Preencha o campo nome!"),
    descricao: yup.string().max(100).required("Preencha o campo descrição!")
});


/* ===== COMPONENT ===== */
export function ModalReservar() {
    const { isModalReservarOpen, setIsModalReservarOpen } = useMeetRoom();

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>({
        resolver: yupResolver(validationSchema)
    });


    const [horaReserva, setHoraReserva] = useState('');
    const [idHoraReserva, setIdHoraReserva] = useState('');
    const [dataSelecionada, setDataSelecionada] = useState('');

    const [data, setData] = useState('');
    const [hora, setHora] = useState('');
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');


    /* ===== Recebe a data selecionada no calendário ===== */
    useEffect(() => {
        setHoraReserva(localStorage.getItem('horaReserva') || '');
        setIdHoraReserva(localStorage.getItem('idHoraReserva') || '');
        setDataSelecionada(localStorage.getItem('dataSelecionada') || '');

    }, [isModalReservarOpen]);

    /* ===== Setando data e hora selecionada nos formatos adequados ===== */
    useEffect(() => {
        const dataSel = dataSelecionada.split('/');
        setData(`${dataSel[2]}-${dataSel[1]}-${dataSel[0]}`);//ano-mes-dia

        const horaSel = horaReserva.split('-');
        setHora(horaSel[0].trim());
    }, [dataSelecionada, horaReserva]);


    async function handleReservar(dataForm: FormData)  {
        
        const dados = {
            data,
            hora,
            id_horario: Number.parseInt(idHoraReserva),
            usuario: dataForm.nome.toLocaleUpperCase(),
            descricao: dataForm.descricao.toLocaleUpperCase(),
        };

        // Enviando dados para o back-end
        try {           
            const response = await api.post('/reservas', dados);

            if (response.status === 200) {
                toast.success('Reserva registrada com sucesso');               
            }

        } catch (error: any) {

            if(error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            
            if(error.response.status === 500) {
                toast.error(error.response.data.error);
            }

            toast.error('Problema ao gerar reserva, verifique as informações');

        } finally {
            localStorage.removeItem('idHoraReserva');
            localStorage.removeItem('horaReserva');
        }
        
        reset();// limpa os dados do formulário
        setIsModalReservarOpen(false);  
    };

    const handleErrorReservar = async (error: any) => {
        console.log(error)
    }

    const handleCloseModal = () => {
        reset();// limpa os dados do formulário
        setIsModalReservarOpen(false);
    }


    return (
        <Modal
            isOpen={isModalReservarOpen}
            onRequestClose={() => handleCloseModal()}
            overlayClassName='react-modal-overlay'
            className='react-modal-content'
        >
            <Container>
                <Form onSubmit={handleSubmit(handleReservar, handleErrorReservar)}>
                    <HeaderFormContainer>
                        <HeaderTitle>Registrar Reserva</HeaderTitle>
                    </HeaderFormContainer>
                    <InputsContainer>
                        <div className='divider-line'>
                            <CustomInput>
                                <input 
                                    type="text"
                                    autoComplete='off'
                                    value={dataSelecionada}
                                    readOnly
                                />
                                <label>
                                    Data
                                </label>
                            </CustomInput>

                            <CustomInput>
                                <input 
                                    type="text"
                                    autoComplete='off'
                                    value={horaReserva}
                                    readOnly
                                />
                                <label>
                                    Horário
                                </label>
                            </CustomInput>
                        </div>
                        <CustomInput className={errors?.nome?.type && 'error'}>
                            <input 
                                {...register('nome')}
                                type="text"
                            />
                            <label>
                                Nome *
                            </label>
                        </CustomInput>
                        {errors?.nome?.type && <InputError type={errors.nome.type} field="nome"/>}

                        <CustomInput className={errors?.descricao?.type && 'error'}>
                            <textarea 
                                {...register('descricao')} 
                            />
                            <label>
                                Descrição *
                            </label>
                        </CustomInput>
                        {errors?.descricao?.type && <InputError type={errors.descricao.type} field="descricao"/>}
                    </InputsContainer>

                    <ButtonsContainer>
                            <Button 
                                bg_color='grey'
                                type='button'
                                onClick={() => handleCloseModal()}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                bg_color='orange'
                                type='submit'
                            >
                                Gravar
                            </Button>
                    </ButtonsContainer>
                </Form>
            </Container>
        </Modal>
    );
}