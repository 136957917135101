import styled from 'styled-components';

const Container = styled.div`
  padding: 2.5rem;
  background-color: ${props => props.theme.color.white};
  min-height: 100vh;

  .table-container {
    border: 1px solid ${props => props.theme.color.light_grey_1};
    border-radius: 5px;

    table {

      thead {
        th {
          position: relative;
          font-size: 1.01rem;
          font-weight: bold;
          color: ${props => props.theme.color.dark_grey_1};


          &:not(:first-child)::before {
            content: "";
            position: absolute;
            height: 35%;
            width: 2px;
            border-radius: 2px;

            top: 50%;
            transform: translateY(-50%);
            left: 0;

            background: ${props => props.theme.color.light_grey_1};       
          }
        }
      }
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  align-items: center;
  margin-bottom: 2.5rem;
`;

const Title = styled.h2`
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${props => props.theme.color.white};
  border-radius: 5px;

  .react-date-picker__wrapper {
    border-radius: 4px;
  }

  .week-day {
    width: 100%;
    margin-bottom: 0.5em;
    padding: 0.5rem 0;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${props => props.theme.color.black};
  }

  .select-date {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg.select-date__arrow {
      font-size: 1.5rem;
      border-radius: 50%;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        background: ${props => props.theme.color.light_grey_2};
      }

    }
  }
`;

export {
  Container,
  HeaderContainer,
  DateContainer,
  Title,
};