import { FormEvent, useEffect } from 'react';
import Modal from 'react-modal';
import { useMeetRoom } from '../../contexts/useMeetRoom';
import api from '../../services/api';
import { toast } from 'react-toastify';

import {
  Container,
  Text,
  ButtonsContainer,
  Button
} from './styles';


Modal.setAppElement('#root');

const ModalDeletar = () => {
  const { isModalDeletarOpen, setIsModalDeletarOpen, currentDataReserva } = useMeetRoom();
  

  /* ===== HANDLE FUNCTIONS ===== */
  async function handleDeletarReserva(event: FormEvent) {
    event.preventDefault();

    try {
      await api.delete(`/reservas/${currentDataReserva.id}`).then((response) => { 
        if (response.status === 200) {
          toast.success('Reserva retirada com sucesso!');
        }
      });
    } catch (error: any) {
      toast.error(`Erro ao remover reserva: ${error.message}`);
    } finally {
      setIsModalDeletarOpen(false);
    }
    

  }


  /* ===== USE EFFECTS ===== */
  useEffect(() => {   

  }, [isModalDeletarOpen]);


  return (
    <Modal
      isOpen={isModalDeletarOpen}
      onRequestClose={() => setIsModalDeletarOpen(false)}
      overlayClassName='react-modal-overlay'
      className='react-modal-content'
    >
      <Container onSubmit={handleDeletarReserva}>
         <Text>{`Retirar reserva de ${currentDataReserva.usuario} para o horário ${currentDataReserva.hora} ?`}</Text>
         <ButtonsContainer>
            <Button color='grey'
              onClick={() => setIsModalDeletarOpen(false)}
            >
              Cancelar
            </Button>
           <Button type='submit' color='orange'>
              Deletar
            </Button>
            
          </ButtonsContainer>
      </Container>
    </Modal>
  );
};

export { ModalDeletar };