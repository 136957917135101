import { FormEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useMeetRoom } from '../../contexts/useMeetRoom';
import api from '../../services/api';

import {
    Container,
    Text,
    ButtonsContainer,
    Button
} from './styles';

Modal.setAppElement('#root');


const ModalProlongarHorario = () => {
    const {
        isModalProlongarHorarioOpen,
        setIsModalProlongarHorarioOpen
    } = useMeetRoom();

    const [dataSelecionada, setDataSelecionada] = useState('');
    const [idHoraParaCopiar, setIdHoraParaCopiar] = useState('');

    useEffect(() => {
        setDataSelecionada(localStorage.getItem('dataSelecionada')!)
        setIdHoraParaCopiar(localStorage.getItem("idHoraParaCopiar")!)
    }, [isModalProlongarHorarioOpen]);

    async function handleProlongarHorario(event: FormEvent) {
        event.preventDefault();

        try {
            const data = dataSelecionada.split('/');
            const dataInvertida = `${data[2]}-${data[1]}-${data[0]}`;

            const response = await api.post(`/reservas/copiar?data=${dataInvertida}`, {
                idDe: Number(idHoraParaCopiar) - 1, 
                idPara: Number(idHoraParaCopiar)
            });
            
            if(response.status === 201) {
                toast.success("Reserva copiada com sucesso!");
            }

        } catch(error) {
            console.log(error);
            toast.error("Erro ao copiar a reserva");
        } finally {
            setIsModalProlongarHorarioOpen(false);
        }
    }


    return(
        <Modal
            isOpen={isModalProlongarHorarioOpen}
            onRequestClose={() => setIsModalProlongarHorarioOpen(false)}
            overlayClassName='react-modal-overlay'
            className='react-modal-content'
        >
            <Container onSubmit={handleProlongarHorario}>
                <Text>Deseja copiar os dados da reserva anterior?</Text>
                <ButtonsContainer>
                    <Button color='grey'
                        onClick={() => {
                            setIsModalProlongarHorarioOpen(false)
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button type='submit' color='orange'>
                        Copiar
                    </Button>
                </ButtonsContainer>
            </Container>
        </Modal>
    );
}

export { ModalProlongarHorario }