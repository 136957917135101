import { Routes } from './routes';
import { GlobalStyles } from './styles/global';
import { ThemeProvider } from 'styled-components';
import light from './styles/themes/light';
import dark from './styles/themes/dark';


import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { MeetRoomProvider } from './contexts/useMeetRoom';
import { ModalDeletar } from './components/ModalDeletar';
import { ModalReservar } from './components/ModalReservar';
import { ModalRecorrencia } from './components/ModalRecorrencia';
import { ModalDeletarRecorrencia } from './components/ModalDeletarRecorrencia';
import { ModalProlongarHorario } from './components/ModalProlongarHorario';
import { ModalUpdate } from './components/ModalUpdate';

export const App = () => {
  const [theme, setTheme] = useState(light);

  const toggleTheme = () => {
    setTheme(theme.title === 'light' ? dark : light)
  }

  return (
    <MeetRoomProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ModalDeletar />
        <ModalReservar />
        <ModalRecorrencia />
        <ModalDeletarRecorrencia />
        <ModalProlongarHorario />
        <ModalUpdate />
        <Routes />
        <ToastContainer autoClose={3000} theme="colored" transition={Flip} />
      </ThemeProvider>
    </MeetRoomProvider>
  );
};


