import { Container } from "./styles";
import errors from '../../utils/errors.json';

/* ===== TIPAGENS ===== */
interface InputErrorProps {
    type: string;
    field: string;
}

/* ===== COMPONENT ===== */
export function InputError({ type, field }: InputErrorProps) {
    // @ts-expect-error
    return (<Container>{errors[field][type]}</Container>);
}