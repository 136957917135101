import styled from 'styled-components';


/* ===== TIPAGENS ===== */
interface ButtonProps {
  color: 'grey' | 'orange';
}


/* ===== STYLED COMPONENTS ===== */
const Container = styled.form`
  height: 15.6rem;
  width: 25rem;
  background-color: ${props => props.theme.color.white};
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5rem;
`;

const Text = styled.p`
  font-size: 1.25rem;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
`;

const Button = styled.button<ButtonProps>`
  height: 2.5rem;
  width: 7rem;

  border: none;
  border-radius: 10px;
  cursor: pointer;

  font-size: 1.12rem;
  font-weight: bold;

  color: ${props => props.theme.color.white};
  background: ${props => props.color === 'grey' ? props.theme.color.dark_grey_1 : props.theme.color.orange};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: all .15s linear;

  &:hover {
    transform: translateY(-5px);
  }

`;

export {
  Container,
  Text,
  ButtonsContainer,
  Button
};