import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #0004;
  position: absolute;
  display: ${props => props.hidden === true ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;