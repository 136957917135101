import { useState, useEffect } from "react";
import { useMeetRoom } from "../../contexts/useMeetRoom";
import DatePicker from "react-date-picker";
import api from "../../services/api";
import Header from "../../components/Header";
import { TableTr } from "../../components/TableTr";
import Loading from "../../components/Loading";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import {
  TableContainer,
  Table, 
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

import {
  Container,
  HeaderContainer,
  DateContainer,
  Title,
} from "./styles";



/* ===== TIPAGENS ===== */
type Horario = {
  id: number;
  hora: string;
}

type Reservas = {
  id: number;
  data: string;
  hora: string;
  id_recorrencia: number | null;
  usuario: string;
  descricao: string;
  id_horario: number;
}


/* ===== COMPONENT ===== */
const Agendamentos = () => {
  const {
    isModalReservarOpen, 
    isModalRecorrenciaOpen, 
    isModalDeletarOpen,
    isModalDeletarRecorrenciaOpen,
    isModalProlongarHorarioOpen,
    isModalUpdateOpen
  } = useMeetRoom();

  const [reservas, setReservas] = useState<Reservas[]>([]);
  const [horarios, setHorarios] = useState<Horario[]>([]);

  const [data, setData] = useState(new Date());
  const [dia, setDia] = useState(data.getDate() !== null ? data.getDate() : "--");
  const [mes, setMes] = useState(data.getMonth() + 1 !== null ? data.getMonth() + 1 : "--");
  const [ano, setAno] = useState(data.getFullYear() !== null ? data.getFullYear() : "--");

  const [diaSemana, setDiaSemana] = useState('');
  const [loading, setLoading] = useState(true);

  localStorage.setItem("idsMarcados", JSON.stringify(null));


  async function handleGetReservasCurrentDate() {
    try {
      await api.get(`/reservas?data=${ano}-${mes}-${dia}`).then((response) => {
        setReservas(response.data);
      });
    } catch(error) {
      console.log(error);
    }
    
  }


  /* ===== Recebe os dados da data atual assim que a tela é mostrada ===== */
  useEffect(() => {
    setLoading(true);

    const dia = data.getDay();// retorna o dia em relação a semana

    switch (dia) {
      case 0:
        setDiaSemana("Domingo");
        break;
      case 1:
        setDiaSemana("Segunda-Feira");
        break;
      case 2:
        setDiaSemana("Terça-Feira");
        break;
      case 3:
        setDiaSemana("Quarta-Feira");
        break;
      case 4:
        setDiaSemana("Quinta-Feira");
        break;
      case 5:
        setDiaSemana("Sexta-Feira");
        break;
      case 6:
        setDiaSemana("Sábado");
        break;
      default:
        setDiaSemana("--");
    }

    localStorage.setItem("reservaID", JSON.stringify([]));

    handleGetReservasCurrentDate();
    
  }, [isModalReservarOpen, isModalRecorrenciaOpen, isModalDeletarOpen, isModalDeletarRecorrenciaOpen, isModalProlongarHorarioOpen, isModalUpdateOpen]);


  /* ===== Executado assim que a data é alterada ===== */
  useEffect(() => {
    localStorage.removeItem("reservaID");
    localStorage.removeItem("horarioID");

    const setarDataSelecionada = () => {
      localStorage.setItem("dataSelecionada", `${dia}/${mes}/${ano}`);
    };

    const setarDadosDataSelecionada = async () => {
      setLoading(true);

      await api.get(`/reservas?data=${ano}-${mes}-${dia}`).then((response) => {
        setReservas(response.data);
        localStorage.setItem('reservas', JSON.stringify(reservas));
        setLoading(false);
      });
    };

    setarDataSelecionada();
    setarDadosDataSelecionada();

  }, [dia, mes, ano]);


  /* ===== Recebe os id's e horarios da tabela de horarios para renderizar na tela ===== */
  useEffect(() => {

    const setarHorarios = async () => {
      await api.get("/horarios").then((response) => {
        setHorarios(response.data);
      });
    }
    
    setarHorarios();
    setLoading(false);

  }, [reservas]);


  const handleChangeData = (data: Date) => {
    setData(data);

    setDia(data.getDate());
    setMes(data.getMonth() + 1);
    setAno(data.getFullYear());

    const dia = data.getDay();

    switch (dia) {
      case 0:
        setDiaSemana("Domingo");
        break;
      case 1:
        setDiaSemana("Segunda-Feira");
        break;
      case 2:
        setDiaSemana("Terça-Feira");
        break;
      case 3:
        setDiaSemana("Quarta-Feira");
        break;
      case 4:
        setDiaSemana("Quinta-Feira");
        break;
      case 5:
        setDiaSemana("Sexta-Feira");
        break;
      case 6:
        setDiaSemana("Sábado");
        break;
      default:
        setDiaSemana("--");
    }
  };

  const procurarDados = (id: number) => {
    let currentReserva;

    reservas.map((reserva) => {
      if (reserva.id_horario === id) {

        currentReserva = reserva;

        return currentReserva;
      } else {
        return null;
      }
    });

    return currentReserva;
  };

  return (
    <>
      <Header />
      <Loading hidden={!loading} />
      <Container>
        <HeaderContainer>
          <Title>Agendamento da Sala de Reunião</Title>
          <DateContainer>
            <p className="week-day">{diaSemana}</p>
            <div className="select-date">
              <FaAngleLeft 
                className="select-date__arrow" 
                onClick={() => {
                  const newDate = data;
                  newDate.setDate(newDate.getDate() - 1)
                  handleChangeData(newDate)
                }}
              />
              <DatePicker
                clearIcon={null}
                format="dd/MM/yyyy"
                value={data}
                onChange={(data: Date) => {handleChangeData(data)}}
              />
              <FaAngleRight 
                className="select-date__arrow" 
                onClick={() => {
                  const newDate = data;
                  newDate.setDate(newDate.getDate() + 1)
                  handleChangeData(newDate)
                }}
              />
            </div>
            
          </DateContainer>
        </HeaderContainer>
      
        {/* TABLE MUI  */}
        <TableContainer className="table-container" >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="15%" align="center">Horário</TableCell>
                <TableCell width="20%" align="center">Usuário</TableCell>
                <TableCell width="30%" align="center">Descrição</TableCell>
                <TableCell width="15%" align="center">Recorrência</TableCell>
                <TableCell width="20%" align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {horarios.map((horario, index) => (
                    <TableTr key={index} horario={horario} dados={procurarDados(horario.id)}/>
                ))} 
            </TableBody>
          </Table>
        </TableContainer>

      </Container>
    </>
  );
};

export default Agendamentos;
