import { useState, useEffect } from "react";
import { useMeetRoom } from "../../contexts/useMeetRoom";
import Tooltip from "@material-ui/core/Tooltip";
import { TableCell, TableRow } from "@mui/material";
import { FaCalendarTimes, FaPlusCircle, FaRegClock, FaTrash } from 'react-icons/fa';
import { MdContentCopy } from "react-icons/md";
import { RiPencilFill } from 'react-icons/ri';

import { Button, Hora } from "./styles";

/* ===== TIPAGENS ===== */
type TableTrProps = {
  dados: Dados | undefined;
  horario: Horario;
}

type Dados = {
  id: number;
  data: string;
  hora: string;
  id_recorrencia: string | null;
  usuario: string;
  descricao: string;
  id_horario: number;
}

type Horario = {
  id: number;
  hora: string;
}


/* ===== COMPONENT ===== */
const TableTr = (props: TableTrProps) => {
  const { 
    setIsModalReservarOpen, 
    setIsModalDeletarOpen,
    setIsModalDeletarRecorrenciaOpen,
    setIsModalRecorrenciaOpen,
    setIsModalProlongarHorarioOpen,
    setIsModalUpdateOpen,
    setCurrentDataReserva
  } = useMeetRoom();


  const [dados, setDados] = useState(props.dados);

  const [reservado, setReservado] = useState(false);
  const [recorrencia, setRecorrencia] = useState(false);

  const [mostrarReservar, setMostrarReservar] = useState(false);
  const [mostrarDeletar, setMostrarDeletar] = useState(false);
  const [mostrarRecorrencia, setMostrarRecorrencia] = useState(false);
  const [mostrarRetirarRecorrencia, setMostrarRetirarRecorrencia] = useState(false);
  const [mostrarCopiar, setMostrarCopiar] = useState(false);
  const [mostrarUpdate, setMostrarUpdate] = useState(false);


  /* ===== Seta novamente os dados da variavel "dados" ===== */
  //observando mudanças no item "reservas" na localStorage
  useEffect(() => {
    setDados(props.dados);
    setRecorrencia(false);
    setReservado(false);
  }, [props]);

  
  /* ===== Verifica se a reserva feita tem recorrencia ou não ===== */
  useEffect(() => {
    if (dados !== undefined && dados?.id_horario === props.horario.id) {

      if (dados.id_recorrencia === null) {
        setRecorrencia(true);
        setReservado(true);
      } else {
        setRecorrencia(false);
        setReservado(false);
      }
    }
    
  }, [dados]);


  /* ===== Seta variaveis para mostrar/esconder botões de ações ===== */
  useEffect(() => {

    setMostrarReservar(dados !== undefined ? false : true);
    setMostrarRecorrencia(dados !== undefined && dados?.id_recorrencia === (undefined || null) ? true : false);
    setMostrarRetirarRecorrencia(dados !== undefined && dados?.id_recorrencia !== (undefined || null) ? true : false);
    setMostrarDeletar(dados !== undefined ? true : false);
    setMostrarCopiar(false);
    setMostrarUpdate(dados !== undefined ? true : false);


    if(dados !== undefined) {
      localStorage.setItem("reservaID", JSON.stringify(dados.id));
      localStorage.setItem("horarioID", JSON.stringify(dados.id_horario));

      localStorage.setItem("hasReservaBefore", JSON.stringify(true));
      setMostrarCopiar(false);
      
    } else {
      const hasReservaBefore = JSON.parse(localStorage.getItem("hasReservaBefore")!)
      if(hasReservaBefore && props.horario.id !== 1) {
        setMostrarCopiar(true);
      }
      
      localStorage.setItem("hasReservaBefore", JSON.stringify(false));

    }


  }, [dados, props.horario]);


  const handleReservar = async (horario: Horario) => {
    
    localStorage.setItem("horaReserva", horario.hora);
    localStorage.setItem("idHoraReserva", JSON.stringify(horario.id));

    setIsModalReservarOpen(true);
  };

  const handleDeletarReserva = async (dados: Dados | undefined) => {
    if(dados === undefined) return;
    setCurrentDataReserva(dados);
    setIsModalDeletarOpen(true);

    // if(dados === undefined) return;

    // const confirma = window.confirm(
    //   `Retirar reserva de ${dados.usuario} para o horário ${dados.hora} ?`
    // );

    // if (confirma === true) {
    //   try {
    //     await api.delete(`/reservas/${dados.id}`).then((response) => { 
    //       if (response.status === 200) {
    //         window.location.reload();
    //       }
    //     });
    //   } catch (error: any) {
    //     toast.error(`Erro ao remover reserva: ${error.message}`);
    //   } finally {
    //   }
    // } else {
    //   return;
    // }
  };

  const handleCopiar = async (horario: Horario) => {
    if(horario === undefined) return;
    localStorage.setItem("idHoraParaCopiar", JSON.stringify(horario.id));

    setIsModalProlongarHorarioOpen(true);
  }

  const handleRecorrencia = async (
    horario: Horario,    
    nome = "",
    descricao = "",
    idReserva?: number
  ) => {
    
    localStorage.setItem("horaReserva", horario.hora);
    localStorage.setItem("nome", nome);
    localStorage.setItem("descricao", descricao);
    localStorage.setItem("idReserva", JSON.stringify(idReserva));

    setIsModalRecorrenciaOpen(true);
  };

  const handleDeletarRecorrencia = async (dados?: Dados) => {
    if(dados === undefined) return;
    setCurrentDataReserva(dados);
    setIsModalDeletarRecorrenciaOpen(true);


    // const confirma = window.confirm(
    //   `Retirar recorrencia ID ${dados?.id_recorrencia} para o horário ${dados?.hora} e todas as reservas posteriores ?`
    // );

    // if (confirma === true) {
    //   try {
    //     await api.delete(`/recorrencias/${dados?.id}`).then((response) => {
    //       if (response.status === 200) {
    //         window.location.reload();
    //       }
    //     });
    //   } catch (error: any) {
    //     toast.error(`Erro ao deletar recorrência: ${error.message}`);
    //   } finally {
    //   }
    // } else {
    //   return;
    // }
  };

  const handleUpdate = async (dados: Dados | undefined, horario: Horario) => {
    localStorage.setItem("horaReserva", horario.hora);
    localStorage.setItem("idReservaParaEditar", JSON.stringify(dados?.id));
    localStorage.setItem("idRecorrencia", dados?.id_recorrencia || '');
    localStorage.setItem("nome", dados?.usuario || '');
    localStorage.setItem("descricao", dados?.descricao || '');

    setIsModalUpdateOpen(true);
  }


  return (
    <>   
      <TableRow>
          <TableCell align="center">
            <Hora className={(dados !== undefined) ? 'reserved' : ''}>
              {props.horario.hora}
            </Hora>
          </TableCell>
          <TableCell align="center">{dados?.usuario}</TableCell>
          <TableCell align="center" 
            style={{
              maxWidth: '200px',
              overflow: 'hidden',
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {dados?.descricao}
          </TableCell>
          <TableCell align="center">
            {(dados !== undefined) && (dados.id_recorrencia !== null) ? 'SIM' : 'NÃO'}
          </TableCell>
          <TableCell align="center">
              {mostrarReservar ? (
                  <Tooltip title="Reservar esse horário" placement="top">
                    <Button
                        onClick={() => handleReservar(props.horario)}
                    >
                        <FaPlusCircle size={20}/>
                    </Button>
                  </Tooltip>
              ) : (null)}                           
                   
                
              {mostrarCopiar ? (
                <Tooltip title="Prolongar horário acima" placement="top">
                  <Button
                      onClick={() => handleCopiar(props.horario)}
                  >
                      <MdContentCopy size={20}/>
                  </Button>
                </Tooltip>
              ) : (null)}


              {mostrarUpdate ? (
                <Tooltip title="Editar Reserva" placement="top">
                  <Button
                  onClick={() => handleUpdate(dados, props.horario)}
                  >
                      <RiPencilFill size={20}/>
                  </Button>
                </Tooltip>
              ) : (null)}

              {mostrarRecorrencia ? (
                <Tooltip title="Gerar Recorrência" placement="top">
                  <Button
                      onClick={() => handleRecorrencia(
                        props.horario,
                        dados?.usuario,
                        dados?.descricao,
                        dados?.id
                      )}
                  >
                      <FaRegClock size={20}/>
                  </Button>
                </Tooltip>
              ) : (null)}


              {mostrarRetirarRecorrencia ? (
                <Tooltip title="Retirar Recorrência" placement="top">
                  <Button
                      onClick={() => handleDeletarRecorrencia(dados)}
                  >
                      <FaCalendarTimes size={20}/>
                  </Button>
                </Tooltip>
              ) : (null)}


              {mostrarDeletar ? (
                  <Tooltip title="Cancelar Reserva" placement="top">
                    <Button
                        onClick={() => handleDeletarReserva(dados) }
                    >
                        <FaTrash size={20}/>
                    </Button>
                  </Tooltip>
              ) : (null)}
                
          </TableCell> 
      </TableRow>
    </>
  );
};

export { TableTr };
