import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  background-color: ${props => props.theme.color.dark_grey_2};

  button {
    position: absolute;
    right: 0;
    margin-right: 1rem;
    width: 7rem;

    transition: all .2s ease;

    &:hover {
      transform: translateY(-3px);
    }
  }

  @media (max-width: 800px) {
    justify-content: space-between;
    padding: 1rem;
    gap: 3rem;


    button {
      display: block;
      position: static;
      min-width: 5rem;
      margin-right: 0;
    }

  }
`;

const LogoImage = styled.div`
  display: block;
  max-width: 20rem;
  width: 80%;

  cursor: pointer;

  img {
    object-fit: cover;
    max-width: 70%;
  }
`;


export { Container, LogoImage };
