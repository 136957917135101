import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useMeetRoom } from '../../contexts/useMeetRoom';
import api from '../../services/api';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputError } from '../InputError';

import { 
    Button, 
    ButtonsContainer, 
    Container, 
    CustomInput, 
    Form, 
    HeaderFormContainer, 
    HeaderTitle, 
    InputsContainer 
} from './styles';



/* ===== TIPAGENS ===== */
type FormData = {
    nome: string;
    descricao: string;
}


/* ===== OTHERS ===== */
Modal.setAppElement("#root");

const validationSchema = yup.object({
    nome: yup.string().max(20).required("Preencha o campo nome!"),
    descricao: yup.string().max(100).required("Preencha o campo descrição!")
});


/* ===== COMPONENT ===== */
export function ModalUpdate() {
    const {
        isModalUpdateOpen,
        setIsModalUpdateOpen
    } = useMeetRoom();

    // Hook Form
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<FormData>({
        resolver: yupResolver(validationSchema)
    })

    const [horaReserva, setHoraReserva] = useState('');
    const [idReserva, setIdReserva] = useState(0);
    const [idRecorrencia, setIdRecorrencia] = useState<string  | null>(null);
    const [dataSelecionada, setDataSelecionada] = useState('');


    const [data, setData] = useState('');
    const [hora, setHora] = useState('');
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');

    /* ===== Recebe a data selecionada no calendário ===== */
    useEffect(() => {
        setHoraReserva(localStorage.getItem('horaReserva') || '');
        setIdReserva(Number(localStorage.getItem('idReservaParaEditar')));
        setIdRecorrencia(localStorage.getItem('idRecorrencia') || '');
        setDataSelecionada(localStorage.getItem('dataSelecionada') || '');

        setNome(localStorage.getItem('nome') || '');
        setDescricao(localStorage.getItem('descricao') || '');

        setValue('nome', localStorage.getItem('nome') || '');
        setValue('descricao', localStorage.getItem('descricao') || '');

    }, [isModalUpdateOpen]);


    /* ===== Setando data e hora selecionada nos formatos adequados ===== */
    useEffect(() => {
        const dataSel = dataSelecionada.split('/');
        setData(`${dataSel[2]}-${dataSel[1]}-${dataSel[0]}`);//ano-mes-dia

        const horaSel = horaReserva.split('-');
        setHora(horaSel[0].trim());
    }, [dataSelecionada, horaReserva]);

    const handleUpdate = async (datasForm: FormData) => {

        try {
            const dados = {
                usuario: datasForm.nome.toLocaleUpperCase(),
                descricao: datasForm.descricao.toLocaleUpperCase(),
            };

            const response = await api.put(`/reservas/${idReserva}${idRecorrencia !== '' ? '?id_recorrencia=' + idRecorrencia : ''}`, dados);
            
            if (response.status === 200) {
                toast.success('Reserva atualizada com sucesso');               
            }
            

        } catch (error: any) {
            console.log(error);

            if(error.response.data.status === 400) {
                toast.error(error.response.data.error);
            }

        } finally {
            localStorage.removeItem('idReservaParaEditar');
            localStorage.removeItem('horaReserva');

            reset();
            setIsModalUpdateOpen(false); 
        } 
    }

    const handleCloseModal = () => {
        localStorage.removeItem('idReservaParaEditar');
        localStorage.removeItem('horaReserva');
        
        reset();
        setIsModalUpdateOpen(false);
    }


    return(
        <Modal
            isOpen={isModalUpdateOpen}
            onRequestClose={() => handleCloseModal()}
            overlayClassName='react-modal-overlay'
            className='react-modal-content'
        >
            <Container>
                <Form onSubmit={handleSubmit(handleUpdate)}>
                    <HeaderFormContainer>
                        <HeaderTitle>Atualizar Reserva</HeaderTitle>
                    </HeaderFormContainer>
                    <InputsContainer>
                        <div className='divider-line'>
                            <CustomInput>
                                <input 
                                    type="text"
                                    autoComplete='off'
                                    value={dataSelecionada}
                                    readOnly
                                />
                                <label>
                                    Data
                                </label>
                            </CustomInput>

                            <CustomInput>
                                <input 
                                    type="text"
                                    autoComplete='off'
                                    value={horaReserva}
                                    readOnly
                                />
                                <label>
                                    Horário
                                </label>
                            </CustomInput>
                        </div>
                        <CustomInput className={errors?.nome?.type && 'error'}>
                            <input 
                                {...register('nome')}
                                type="text"
                            />
                            <label>
                                Nome *
                            </label>
                        </CustomInput>
                        {errors?.nome?.type && <InputError type={errors.nome.type} field="nome"/>}

                        <CustomInput className={errors?.descricao?.type && 'error'}>
                            <textarea 
                                {...register('descricao')}                                              
                            />
                            <label>
                                Descrição *
                            </label>
                        </CustomInput>
                        {errors?.descricao?.type && <InputError type={errors.descricao.type} field="descricao"/>}
                    </InputsContainer>

                    <ButtonsContainer>
                            <Button 
                                bg_color='grey'
                                type='button'
                                onClick={() => handleCloseModal()}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                bg_color='orange'
                                type='submit'
                            >
                                Gravar
                            </Button>
                    </ButtonsContainer>
                </Form>
            </Container>
        </Modal>
    );
}