// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: 'light',
    
    color: {
        primary: '#f36e21',
        secondary: '#231f20',

        black: '#000000',
        white: '#FFFFFF',

        grey: '#95989A',
        dark_grey_1: '#57585a',
        dark_grey_2: '#231F20',
        light_grey_1: '#D1D2D4',
        light_grey_2: '#F1F1F1', 

        green: '#139c45',
        dark_green: '#006B18',
        light_green_1: '#56CD71',
        light_green_2: '#BEEECF',

        orange: '#F36E21',
        dark_orange: '#E65E0E',
        light_orange_1: '#FF975B',
        light_orange_2: '#FFD5BD',
        light_orange_3: '#F29765',


        dark_red: '#b00e16',
        red: '#ee1d23',
        yellow: '#ffe436',
        blue: '#2e66ff',
        purple: '#8257e6',
    },
}