import CircularProgress from '@material-ui/core/CircularProgress';
import { Wrapper } from './styles';

/* ===== TIPAGENS ===== */
interface LoadingProps {
  hidden: boolean;
}


/* ===== COMPONENT ===== */
const Loading = (props: LoadingProps) => {
  return (
    <Wrapper hidden={props.hidden}>
      <CircularProgress color="secondary" />
    </Wrapper>
  );
};

export default Loading;