import { 
  BrowserRouter, 
  Routes as Rotas, 
  Route, 
  Navigate
} from 'react-router-dom';

import AgendamentosPage from './pages/Agendamentos';
import { Login } from './pages/Login';

export const Routes = () => {
  return (
      <BrowserRouter>
        <Rotas>
          <Route path="/login" element={<Login />} />
          <Route path="/agendamentos" element={<AgendamentosPage />} />
          <Route path='/'
            element={
              localStorage.getItem('auth') 
              ? <Navigate to="/agendamentos" />
              : <Navigate to="/login" />
            }
          />
        </Rotas>
        
      </BrowserRouter>
  );
};

