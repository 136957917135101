import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useMeetRoom } from '../../contexts/useMeetRoom';
import { IoMdArrowDropdown } from 'react-icons/io';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { InputError } from '../InputError';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Button,
  ButtonsContainer,
  Container,
  CustomInput,
  CustomSelect,
  HeaderFormContainer,
  HeaderTitle,
  InputsContainer
} from './styles';



/* ===== TIPAGENS ===== */
type FormData = {
  intervalo: number;
  quantidade: 1 | 7 | 30;
}


/* ===== OTHERS ===== */
Modal.setAppElement('#root');

const validationSchema = yup.object({
  intervalo: yup.number().required("Escolha um intervalo!"),
  quantidade: yup.number().min(1).required("Escolha uma quantidade!")
});


/* ===== COMPONENT ===== */
export function ModalRecorrencia() {
  const { isModalRecorrenciaOpen, setIsModalRecorrenciaOpen } = useMeetRoom();

  // Hook Form
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema)
  })


  /* ===== STATES ===== */
  const [horaReserva, setHoraReserva] = useState('');

  const [hora, setHora] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');

  const [vezes, setVezes] = useState(0);
  const [intervalo, setIntervalo] = useState(0);
  const [idReserva, setIdReserva] = useState(0);


  /* ===== USE EFFECTS ===== */
  // Recebe a data selecionada no calendário
  useEffect(() => {
    setHoraReserva(localStorage.getItem('horaReserva') || '');
    setNome(localStorage.getItem('nome') || '');
    setDescricao(localStorage.getItem('descricao') || '');
    setIdReserva(Number(localStorage.getItem('idReserva')));

  }, [isModalRecorrenciaOpen]);

  useEffect(() => {
    const horaSel = horaReserva.split('-');
    setHora(horaSel[0].trim());
  }, [horaReserva]);


  const handleGerarRecorrencia = async (datasForm: FormData) => {

    const dados = {
      tempo_recorrencia: datasForm.intervalo,
      quantidade_recorrencia: datasForm.quantidade,
    };

    try {
      const response = await api.post(`/recorrencias/${idReserva}`, dados)

      if (response.status === 200) {
        toast.success('Recorrência gerada com sucesso!');
        localStorage.removeItem('horaReserva');
        localStorage.removeItem('nome');
        localStorage.removeItem('descricao');
        localStorage.removeItem('idReserva');

        reset();
        setIsModalRecorrenciaOpen(false);
      }

    } catch (error: any) {

      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      }
      if (error.response.status === 500) {
        toast.error('Erro 500 - Erro interno de servidor');
      }
    } finally {

    }
  };

  const handleCloseGerarRecorrencia = () => {
    reset();
    setIsModalRecorrenciaOpen(false);
  }

  return (
    <Modal
      isOpen={isModalRecorrenciaOpen}
      onRequestClose={() => handleCloseGerarRecorrencia()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container onSubmit={handleSubmit(handleGerarRecorrencia, (error) => { console.log(error) })}>
        <HeaderFormContainer>
          <HeaderTitle>Registrar Recorrência</HeaderTitle>
        </HeaderFormContainer>

        <InputsContainer>
          <div className='divider-line'>
            <div>
              <CustomInput>
                <input
                  type="text"
                  value={horaReserva}
                  readOnly
                />
                <label>
                  Horário
                </label>
              </CustomInput>
            </div>
            <div>
              <CustomSelect className={errors?.intervalo?.type && 'error'}>
                <select {...register('intervalo')}>
                  <option selected>Selecione...</option>
                  <option value={1}>Todo dia</option>
                  <option value={7}>Toda semana</option>
                  <option value={30}>Todo mês</option>
                </select>
                <label>
                  Intervalo (Dias) *
                </label>
                <IoMdArrowDropdown className='custom-arrow' />
              </CustomSelect>
              {errors?.intervalo?.type && <InputError type={errors.intervalo.type} field="intervalo" />}
            </div>


            <div>
              <CustomInput className={errors?.quantidade?.type && 'error'}>
                <input
                  type="number"
                  placeholder='Digite um valor'
                  {...register('quantidade')}
                />
                <label>
                  Quantidade (Vezes) *
                </label>
              </CustomInput>
              {errors?.quantidade?.type && <InputError type={errors.quantidade.type} field="quantidade" />}
            </div>

          </div>
          <CustomInput>
            <input
              type="text"
              value={nome}
              readOnly
            />
            <label>
              Nome
            </label>
          </CustomInput>

          <CustomInput>
            <textarea
              value={descricao}
              readOnly
            />
            <label>
              Descrição
            </label>
          </CustomInput>
        </InputsContainer>

        <ButtonsContainer>
          <Button bg_color='grey'
            onClick={() => handleCloseGerarRecorrencia()}
          >
            Cancelar
          </Button>
          <Button bg_color='orange'
            type='submit'
          >
            Gravar
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
}