import styled from 'styled-components';


/* ===== STYLED COMPONENTS ===== */
const Button = styled.button`
  padding: 0.3rem;
  border-radius: 5px;
  border: none;
  background: ${props => props.theme.color.white};
  box-shadow: 0px 1px 2px #00000029;

  transition: all .2s linear;

  & + & {
    margin-left: 0.5rem;
  }

  &:hover {
    transform: translateY(-5px);
  }

  svg {
      display: block;
      font-size: 1rem;
      color: ${props => props.theme.color.dark_grey_1};
    }
`;

const Hora = styled.span`

  color: ${props => props.theme.color.green};
  font-weight: bold;

  &.reserved {
    color: ${props => props.theme.color.black};
    font-weight: normal;
  }
`;

export {
  Button,
  Hora
};