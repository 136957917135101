import { useNavigate } from 'react-router-dom';
import { Container, LogoImage } from './styles';
import DobesLogo from '../../assets/images/dobes_one.png';
import { SmallButton } from '../Buttons/SmallButton/styles';


/* ===== TIPAGENS ===== */
type HeaderProps = {
  title?: string;
}


/* ===== COMPONENT ===== */
const Header = (props: HeaderProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('auth');
    navigate('/login');
  };

  return (
    <Container>
      <LogoImage>
        <img src={DobesLogo} alt="Logo Dobesone" />
      </LogoImage>
      <SmallButton 
        color_options="orange"
        onClick={handleLogout}
      >
        Sair
      </SmallButton>
    </Container>
  );
};

export default Header;
