import { FormEvent } from 'react';
import Modal from 'react-modal';
import { useMeetRoom } from '../../contexts/useMeetRoom';
import api from '../../services/api';
import { toast } from 'react-toastify';

import {
  Container,
  Text,
  ButtonsContainer,
  Button
} from './styles';


Modal.setAppElement('#root');

const ModalDeletarRecorrencia = () => {
  const { isModalDeletarRecorrenciaOpen, setIsModalDeletarRecorrenciaOpen, currentDataReserva } = useMeetRoom();
  

  /* ===== HANDLE FUNCTIONS ===== */
  async function handleDeletarReserva(event: FormEvent) {
    event.preventDefault();

    try {
        await api.delete(`/recorrencias/${currentDataReserva.id}`).then((response) => {
          if (response.status === 200) {
            toast.success('Recorrência retirada com sucesso!');
          }
        });
      } catch (error: any) {
        toast.error(`Erro ao deletar recorrência: ${error.message}`);
      } finally {
        setIsModalDeletarRecorrenciaOpen(false);
      }
    
  }


  return (
    <Modal
      isOpen={isModalDeletarRecorrenciaOpen}
      onRequestClose={() => setIsModalDeletarRecorrenciaOpen(false)}
      overlayClassName='react-modal-overlay'
      className='react-modal-content'
    >
      <Container onSubmit={handleDeletarReserva}>
         <Text>{`Retirar recorrência do horário ${currentDataReserva?.hora} e todas as reservas posteriores ?`}</Text>
         <ButtonsContainer>
         <Button color='grey'
              onClick={() => {
                console.log(currentDataReserva);
                setIsModalDeletarRecorrenciaOpen(false)
              }}
            >
              Cancelar
            </Button>
           <Button type='submit' color='orange'>
              Retirar
            </Button>
          </ButtonsContainer>
      </Container>
    </Modal>
  );
};

export { ModalDeletarRecorrencia };