import styled from "styled-components";


/* ===== TIPAGENS ===== */
interface ButtonProps {
    bg_color: 'orange' | 'grey';
}


/* ===== STYLED COMPONENTS ===== */
const Container = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 800px;
    border-radius: 10px;
    padding: 2.5rem;

    background: ${props => props.theme.color.white};

    @media (max-width: 1500px) {
        width: 100%;
        margin: 0 1.5rem;
    }
`;

const HeaderFormContainer = styled.div`
    width: 100%;
    margin-bottom: 2.5rem;
`;

const HeaderTitle = styled.h2`
    text-align: left;
    font-size: 1.7rem;
    font-weight: bold;
    color: ${props => props.theme.color.dark_grey_1};
`;

const InputsContainer = styled.div`
    width: 100%;

    .divider-line {
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;

        div {
            margin: 0;
            flex: 1;

            input {
                text-align: center;
            }
        }
    }
`;

const CustomInput = styled.div`
    position: relative;
    width: 100%;
 
    & + & {
        margin-top: 2rem;
    }

    label {
        position: absolute;
        font-size: 1rem;
        font-weight: bold;
        color: ${props => props.theme.color.dark_grey_1};
        background: ${props => props.theme.color.white};
        z-index: 2;
        top: 50%;
        left: 1.75rem;
        pointer-events: none;
        
        transform: translate(-1rem, -2.1rem);
        padding: 0 1rem;
    }

    input {
        width: 100%;
        height: 3rem;

        border: 1px solid ${props => props.theme.color.dark_grey_1};
        border-radius: 10px;
        padding: 0.75rem 1.75rem;
        outline: none;
        font-size: 1rem;

        color: ${props => props.theme.color.dark_grey_1};
        background: transparent;
    }

    textarea {
        resize: none;
        width: 100%;
        height: 7rem;

        border: 1px solid ${props => props.theme.color.dark_grey_1};
        border-radius: 10px;
        padding: 0.75rem 0;
        padding-left: 1.75rem;
        outline: none;
        font-size: 1rem;

        color: ${props => props.theme.color.dark_grey_1};
        background: transparent;
        
        & + label {
            transform: translate(-1rem, -4.1rem);
        }
    }

    // STYLE VALIDATION
    &.error {
        input {
            border-color: ${props => props.theme.color.red}
        }
         
        label {
            color: ${props => props.theme.color.red}
        }

        textarea {
            border-color: ${props => props.theme.color.red}
        }
    }
`;


const CustomSelect = styled.div`
    position: relative;
    width: 100%;

    // Para verificar se usuário selecionou algo
    option[value=""][disabled] {
        display: none;
    }

    label {
        position: absolute;
        font-size: 1rem;
        font-weight: bold;
        color: ${props => props.theme.color.dark_grey_1};
        background: ${props => props.theme.color.white};
        z-index: 2;
        top: 50%;
        left: 1.75rem;
        pointer-events: none;
        
        transform: translate(-1rem, -2.1rem);
        padding: 0 1rem;
    }

    select {
        width: 100%;
        height: 3rem;

        border: 1px solid ${props => props.theme.color.dark_grey_1};
        border-radius: 10px;
        padding: 0.75rem 1.75rem;
        outline: none;
        font-size: 1rem;

        color: ${props => props.theme.color.dark_grey_1};
        background: transparent;
        appearance: none;
        cursor: pointer;

        option {
            appearance: none;
            padding: 1rem;

            &:nth-child(2) {
                border-radius: 10px 10px 0 0;
                /* background: red; */
            }

            &:last-child {
                border-radius: 0 0 10px 10px;
            }
        }
    }

    svg.custom-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;

        color: ${props => props.theme.color.dark_grey_1};
        font-size: 1.5rem;
        pointer-events: none;
    }

    // STYLE VALIDATION
    &.error {
        select {
            border-color: ${props => props.theme.color.red}
        }
         
        label {
            color: ${props => props.theme.color.red}
        }
    }
`;


const ButtonsContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-top: 2rem;
    justify-content: center;
`;

const Button = styled.button<ButtonProps>`
    height: 2.5rem;
    width: 10rem;

    font-size: 1.12rem;
    font-weight: bold;
    color: ${props => props.theme.color.white};
    background: ${props => props.bg_color === 'grey' ? props.theme.color.dark_grey_1 : props.theme.color.orange };

    border: none;
    outline: none;
    border-radius: 10px;
    transition: all .15s linear;

    &:hover {
        transform: translateY(-5px);
    }
`;

export {
    Container,
    HeaderFormContainer,
    HeaderTitle,
    InputsContainer,
    CustomInput,
    CustomSelect,
    ButtonsContainer,
    Button
}