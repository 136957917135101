import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { BigButton } from '../../components/Buttons/BigButton/styles';
import { toast } from 'react-toastify';
import Logo from '../../assets/images/dobes_one.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { InputError } from '../../components/InputError';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Container,
  Painel,
  LogoImage,
  MainText,
  SubText,
  FooterText,
  InputAnimed,
} from './styles';


/* ===== TIPAGENS ===== */
type FormData = {
  login: string;
  password: string;
}


/* ===== OTHERS ===== */
const validationSchema = yup.object({
  login: yup.string().required('Preencha o campo de login'),
  password: yup.string().min(5).required('Preencha o campo de senha')
})


/* ===== COMPONENT ===== */
const Login = () => {
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [showPassWord, setShowPassWord] = useState<boolean>(false);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>({
    resolver: yupResolver(validationSchema)
  });


  const handleLogin = async (dataForm: FormData) => {

    try {
      const response = await api.post('/autenticacao/login', {
        login: dataForm.login,
        senha: dataForm.password,
      });

      if (response.data.auth === true) {
        localStorage.setItem('auth', JSON.stringify(true));
        navigate('/agendamentos');
      }
    } catch (error: unknown) {
      if (typeof error === "string") {
          console.log(error.toUpperCase());
      } else if (error instanceof Error) {
          console.log(error.message); 
      }
      toast.error('Dados para login invalidos.');
    }

    reset();// limpa os dados do formulário
    setLogin('');
    setSenha('');
  };

  return (
    <Container>
      <Painel onSubmit={handleSubmit(handleLogin)}>
        <LogoImage src={Logo} />
        <MainText>Seja bem-vindo!</MainText>
        <SubText>
          Preencha os dados de login e senha para acessar o sistema.
        </SubText>

        <InputAnimed className={errors?.login?.type && 'error'}>
          <input 
            {...register('login', {
              onChange: (event) => {setLogin(event.target.value)}
            })}
            type="text"
            id="login"
            autoComplete='off'
          />
          <label htmlFor="login" className={login.trim() !== '' ? 'isActive' : ''} >
            Login *
          </label>
        </InputAnimed>
        {errors?.login?.type && <InputError type={errors.login.type} field="login"/>}

        <InputAnimed className={errors?.password?.type && 'error'}>
          <input 
            {...register('password', {
              onChange: (event) => {setSenha(event.target.value)}
            })}
            type={showPassWord ? "text" : "password"}
            autoComplete='off'
            id="senha"
            maxLength={30}
          />
          <label htmlFor="senha" className={senha.trim() !== '' ? 'isActive' : ''} >
            Senha *
          </label>
          {
            senha.length > 0 &&
            (showPassWord
            ?
              <FaEyeSlash 
                className='show-hide'
                onClick={() => {setShowPassWord(false)}}
              />
            :
              <FaEye 
                className='show-hide'
                onClick={() => {setShowPassWord(true)}}          
              />)
            
          }
        </InputAnimed>
        {errors?.password?.type && <InputError type={errors.password.type} field="password"/>}
        

        <BigButton 
          type='submit' 
          color_options="orange"
        >
            Acessar
        </BigButton>
        <FooterText>
          @2022 Dobes One | Todos os direitos reservados
        </FooterText>
      </Painel>
    </Container>
  );
};

export { Login }
