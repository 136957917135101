import styled from 'styled-components';
import backgroundLogin from '../../assets/images/fundo-login.svg'; 

export const Container = styled.div`
  width: auto;
  height: 100vh;
  margin: 0;
  background:  ${props => props.theme.color.light_orange_3} url(${backgroundLogin});
  background-position: center;
  background-size: cover;
  background-repeat: none;
  display: flex;
  align-items: center;

  button {
    margin: 2rem 0;
  }
`;

export const Painel = styled.form`
  margin: auto;
  max-width: 36rem;
  background-color: ${props => props.theme.color.secondary};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  max-height: 48.56rem;
  padding: 3.5rem;
  border-radius: 10px;

  button[type="submit"] {
    margin-top: 4.2rem;
  }
`;

export const LogoImage = styled.img`
  width: 100%;
  align-self: center;
  margin-bottom: 3.4rem;
`;

export const MainText = styled.p`
  color: ${props => props.theme.color.white};
  font-weight: bold;
  margin-bottom: 0.7rem;;
`;

export const SubText = styled.p`
  color: ${props => props.theme.color.white};
  font-size: 0.75rem;
  margin-bottom: 4.2rem;
`;

export const InputAnimed = styled.div`
  position: relative;
  width: 100%;
 
  & + & {
    margin-top: 1.5rem;
  }

  label {
    position: absolute;
    font-size: 1.12rem;
    color: ${props => props.theme.color.white};
    background: ${props => props.theme.color.secondary};
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 1.75rem;
    pointer-events: none;
    transition: all .2s ease-out;
  }

  label.isActive {
      transform: translate(-1rem, -2.2rem) scale(0.8);
      padding: 0 0.5rem;
  }

  input {
    width: 100%;
    height: 3rem;

    border: 1px solid ${props => props.theme.color.white};
    border-radius: 10px;
    padding: 0.75rem 0;
    padding-left: 1.75rem;
    outline: none;
    font-size: 1rem;

    color: ${props => props.theme.color.white};
    background: transparent;
  }


  &:focus-within {
    label {
      transform: translate(-1rem, -2.2rem) scale(0.8);
      padding: 0 0.5rem;
    }
  }

  /* Styling Show Hide */
  .show-hide {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: ${props => props.theme.color.white};
    font-size: 1.2rem;
  }

  //STYLE VALIDATION
  &.error {
    input {
      border-color: ${props => props.theme.color.red}
    }
         
    label {
      color: ${props => props.theme.color.red}
    }
        
  } 

`;


export const FooterText = styled.p`
  font-size: 0.7rem;
  color: ${props => props.theme.color.white};
  text-align: center;
`;
