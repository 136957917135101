import { createGlobalStyle } from "styled-components";

export const GlobalStyles =  createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;/*deixa as fontes da pagina suaves*/
}


html {
    @media (max-width: 1080px) {
        font-size: 93.75%;
    }

    @media (max-width: 720px) {
        font-size: 87.5%;
    }
}

body {
    background: ${props => props.theme.color.primary};

    a {
        text-decoration: none;
    }

    button {
        cursor: pointer;
    }

    h1 {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.4rem;
    }

    h2 {
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.5rem;
    }

    h3 {
        font-size: 1.56rem;
        font-weight: 500;
        line-height: 1.5rem;
    }

    h4 {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.62rem;
    }

    h5 {
        font-size: 1.12rem;
        font-weight: 400;
        line-height: 1.25rem;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background: ${props => props.theme.color.white};
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #BBB;
    }

    .react-modal-overlay {
        z-index: 999;
        background: rgba(0, 0, 0, 0.5);

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-modal-content {
        border: none;
        outline: none;  

        @media screen and (max-width: 500px){
            padding: 1.5rem;
        }
    }

    .react-modal-close {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        border: 0;
        background: transparent;

        transition: filter .2s ease;

        &:hover {
            filter: brightness(0.8);
        }

        svg {
            font-size: 1.5rem;
        }
    }


    .Toastify {
        z-index: 1000;
    }

}

`;
