import { createContext, ReactNode, useContext, useEffect, useState } from "react";

/* ===== TIPAGENS ===== */
interface MeetRoomContextData {
    isModalDeletarOpen: boolean;
    setIsModalDeletarOpen: (isModalDeletarOpen: boolean) => void;

    isModalReservarOpen: boolean;
    setIsModalReservarOpen: (isModalReservarOpen: boolean) => void;

    isModalRecorrenciaOpen: boolean;
    setIsModalRecorrenciaOpen: (isModalRecorrenciaOpen: boolean) => void;

    isModalDeletarRecorrenciaOpen: boolean;
    setIsModalDeletarRecorrenciaOpen: (isModalDeletarRecorrenciaOpen: boolean) => void;

    isModalProlongarHorarioOpen: boolean;
    setIsModalProlongarHorarioOpen: (isModalProlongarHorarioOpen: boolean) => void;

    isModalUpdateOpen: boolean;
    setIsModalUpdateOpen: (isModalUpdateOpen: boolean) => void;

    reservas: Reserva[];
    setReservas: (reserva: Reserva[]) => void;

    currentDataReserva: Reserva;
    setCurrentDataReserva: (currentDataReserva: Reserva) => void;
}

interface MeetRoomProviderProps {
    children: ReactNode;
}

interface Reserva {
    id: number;
    data: string;
    hora: string;
    id_recorrencia: string | null;
    usuario: string;
    descricao: string;
    id_horario: number;
}


/* ===== CONTEXT ===== */
export const MeetRoomContext = createContext<MeetRoomContextData>({} as MeetRoomContextData);


/* ===== PROVIDER ===== */
export function MeetRoomProvider({ children }: MeetRoomProviderProps) {
    // STATES MODALS
    const [isModalDeletarOpen, setIsModalDeletarOpen] = useState(false);
    const [isModalReservarOpen, setIsModalReservarOpen] = useState(false);
    const [isModalRecorrenciaOpen, setIsModalRecorrenciaOpen] = useState(false);
    const [isModalDeletarRecorrenciaOpen, setIsModalDeletarRecorrenciaOpen] = useState(false);
    const [isModalProlongarHorarioOpen, setIsModalProlongarHorarioOpen] = useState(false);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);


    // STATES RESERVA
    const [currentDataReserva, setCurrentDataReserva] = useState<Reserva>({} as Reserva);
    const [reservas, setReservas] = useState<Reserva[]>([]);

    useEffect(() => {

    }, []);


    return (
        <MeetRoomContext.Provider value={{
            isModalDeletarOpen,
            setIsModalDeletarOpen,
            isModalReservarOpen, 
            setIsModalReservarOpen,
            isModalRecorrenciaOpen, 
            setIsModalRecorrenciaOpen,
            isModalDeletarRecorrenciaOpen,
            setIsModalDeletarRecorrenciaOpen,
            isModalProlongarHorarioOpen,
            setIsModalProlongarHorarioOpen,
            isModalUpdateOpen,
            setIsModalUpdateOpen,
            reservas,
            setReservas,
            currentDataReserva,
            setCurrentDataReserva
        }}>
            {children}
        </MeetRoomContext.Provider>
    );
}


/* ===== CUSTOM HOOK ===== */
export function useMeetRoom() {
    const context = useContext(MeetRoomContext);

    return context;
}
