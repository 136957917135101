import styled from "styled-components";


/* ===== TIPAGENS ===== */
interface BigButtonProps {
    color_options: 'green' | 'orange' | 'grey'
}

/* ===== STYLED COMPONENTS ===== */
export const BigButton = styled.button<BigButtonProps>`
    max-width: 43rem;
    height: 2.8rem;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: capitalize;
    color: ${props => props.theme.color.white};

    background: ${props => 
        props.color_options === 'green' ? props.theme.color.green :
        props.color_options === 'orange' ? props.theme.color.orange :
        props.color_options === 'grey' ? props.theme.color.dark_grey_1 : ''
    };

    border: none;
    border-radius: 10px;
    opacity: 1;

    transition: all .2s ease;

    &:hover {
        background: ${props => 
            props.color_options === 'green' ? props.theme.color.light_green_1 :
            props.color_options === 'orange' ? props.theme.color.light_orange_1 :
            props.color_options === 'grey' ? props.theme.color.light_grey_1 : ''
        };
    }

    &:active {
        background: ${props => 
            props.color_options === 'green' ? props.theme.color.dark_green :
            props.color_options === 'orange' ? props.theme.color.dark_orange :
            props.color_options === 'grey' ? props.theme.color.dark_grey_2 : ''
        };
    }

    &:disabled {
        background: ${props => 
            props.color_options === 'green' ? props.theme.color.light_green_2 :
            props.color_options === 'orange' ? props.theme.color.light_orange_2 :
            props.color_options === 'grey' ? props.theme.color.light_grey_2 : ''
        };
    }
    
`;